import * as React from 'react';
import * as styles from './StaticPage.module.scss';
import Layout from "../Layout/Layout";
import GalleryComponent from "../GalleryComponent/GalleryComponent"
import { IContentfulImage } from '../../types/contentfulTypes';

type Props = {
	header: string,
	description?: string,
	images?: IContentfulImage[],
}

const StaticPage: React.FC<Props> = (props: Props) => {
	const images = props.images !== undefined ? props.images.map(item => item.file.url) : [];
	return (
		<Layout imagesToPreload={images}>
			<div className={styles.aboutContainer}>
				<div className={styles.contentWrapper}>
					{ props.header && <h6 className={styles.aboutHeader}>{props.header}</h6> }
					{ props.description && <div className={styles.aboutDescription} dangerouslySetInnerHTML={{ __html: props.description }} /> }
					{ images.length > 0 && <GalleryComponent images={images} /> }
				</div>
			</div>
		</Layout>
	)
}

export default StaticPage
