import * as React from 'react';
import * as styles from './galleryComponent.module.scss';
import { useEffect, useRef, useState } from "react"
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';

type Props = {
  images: string[]
}

const mobileSliderSettings = {
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  infinite: true,
  appendDots: dots => {
    return <MagicSliderDots
      dots={dots}
      numDotsToShow={5}
      dotWidth={30}
    />;
  }
}

const desktopSliderSettings = {
  arrows: true,
  autoplay: false,
}

const GalleryComponent: React.FC<Props> = ({images}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0)

  useEffect(() => {
    const closeModal = () => {
      setModalVisible(false);
      document.body.classList.remove('scrollDisabled')
    }

    const clickListener = (e) => {
      if (e.target.className.includes('modalContainer') || e.target.className.includes('slideWrapper')) {
        closeModal();
      }
    }

    const keyPressListener = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('click', clickListener);
    document.addEventListener('keydown', keyPressListener)

    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keydown', keyPressListener);
    }
  }, [])

  const onPhotoClick = (index) => {
    setModalVisible(true);
    document.body.classList.add('scrollDisabled');
    setInitialSlideIndex(index);
  }

  const renderImage = (url) => {
    const image = new Image();
    image.src = url;
    const {width, height} = image;
    return <div className={styles.slideWrapper}>
      <img
        src={url}
        style={{
          width: width > height ? '100%' : '40%',
        }} />
    </div>
  }

  return (
    <>
      <div className={styles.gallery}>
        {images.map((img, index) => (
          <div onClick={() => {
            onPhotoClick(index)
          }}>
            <img
              src={img}
            />
          </div>
        ))}
      </div>
      <div className={styles.mobileGallery}>
        <Slider {...mobileSliderSettings}>
          {images.map((img) => renderImage(img))}
        </Slider>
      </div>
      {modalVisible && (
        <div className={styles.modalContainer}>
          <div className={styles.desktopSliderGallery}>
            <Slider {...desktopSliderSettings} initialSlide={initialSlideIndex}>
              {images.map((img) => renderImage(img))}
            </Slider>
          </div>
        </div>
      )}
    </>
  )
}

export default GalleryComponent
