import * as React from 'react';
import { graphql } from "gatsby";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import StaticPage from '../components/StaticPage/StaticPage';

type Props = {
  data: {
    privacyPolicyData: {
      header: string,
      content: {
        raw: string,
      }
    }
  }
}

export const query = graphql`
  query($locale: String) {
    privacyPolicyData: contentfulStaticPage(
      node_locale: { eq: $locale }
      slug: { eq: "privacy-policy" }
    ) {
      header
      content {
        raw
      }
    }
  }
`

const Regulations: React.FC<Props> = ({ data: { privacyPolicyData }}: Props) => 
  <StaticPage
    header={privacyPolicyData.header}
    description={documentToHtmlString(JSON.parse(privacyPolicyData.content.raw))}
    images={[]} />

export default Regulations